import React from 'react';
import { Link } from 'gatsby';

const Menu = ({ showFrontpage = false, className = '' }) => {
  return (
    <ul className={`menu ${className}`}>
      {showFrontpage ? (
        <li>
          <Link to="/">Portada</Link>
        </li>
      ) : null}
      <li>
        <Link to="/vistas">Vistas</Link>
      </li>
      <li>
        <Link to="/lugares">Lugares</Link>
      </li>
      <li>
        <Link to="/rutas">Rutas</Link>
      </li>
      <li>
        <Link to="/mapas">Mapas</Link>
      </li>
    </ul>
  );
};

export default Menu;
