import React from 'react';

import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, path }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          url
          image
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        url,
        image
      }
    }
  } = data;

  title = title || defaultTitle;
  description = description || defaultDescription;

  return (
    <>
      <Helmet title={title} titleTemplate={titleTemplate}>
        <html lang="gl" />
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        <meta property="og:url" content={`${url}${path || '/'}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
    </>
  );
};

export default SEO;
